export type TMUser = {
  id: string;
  email: string;
  emailVerified: boolean;
};

export function TMUserFromAPI(obj: any): TMUser {
  return {
    id: obj.id,
    email: obj.email,
    emailVerified: obj.email_verified,
  };
}
